.App {
  font-family: 'Retro Gaming';
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-family: 'Retro Gaming';
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a, a:visited {
  font-family: 'Retro Gaming';  
  color: white;
  text-decoration: none;
}

.box {
  font-family: 'Retro Gaming';
  background:linear-gradient(to right, red, orange);
  width: 60%;
  height: auto;
  padding:5px;
  min-height: 9em;
  flex-direction: row;
  align-items: flex-start;
}

.inner-box{
  font-family: 'Retro Gaming';
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 12em;
  background-color: black;
}


.box h2 {
  font-family: 'Retro Gaming';
  text-align: left;
  text-decoration: none;
  padding-left: 5%;
}
.box button,  input {
  font-family: 'Retro Gaming';
  background-color: #000;
  font-family: monospace;
  border: 2px solid white;
  padding: 1%;
  color: white;
  text-decoration: none;
  float: left;
  width: 30%;
  margin:0 0 0 6%;
  z-index: 1;
}
.box button, input.button {
  font-family: 'Retro Gaming';
  margin-top: 4%;
  font-family: monospace;
  width: 20%;
  background-color: white;
  color: black;
}
.box button:hover, input.button:hover {
    font-family: 'Retro Gaming';
  background-color: black;
  color: white;
}

.box .choice {
  font-family: 'Retro Gaming';
  margin:15% 0 0 20%;
  width: 20%;
  height: 20%;
  font-size: calc(11px + 2vmin);
}

p {
  font-family: 'Retro Gaming';
}
.signup_form, input {
  font-family: 'Retro Gaming';
  margin-top: 4%;
}

.message {
  font-family: 'Retro Gaming';
  float:inline-start;
  font-size: 14px;
  margin:2% 0% 0 4%;
}

.chart-wrapper {
  height: 10%;
  margin-top: 3em;
  padding: 1em;
}
canvas{
  background-color: #ffffff2a;
  width: 20px;
  height: 5em;
}

